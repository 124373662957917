<template>
  <div>
    <!-- 导航栏 -->
    <NavBar />

    <div class="intro">
      <div class="intro__header">法律声明</div>
      <div class="intro__main">
        <div class="title">天存公司网站使用条款和法律限制</div>
        <div class="info">注意：请您在使用本网站前仔细阅读以下条款。使用本网站表明您已接受这些条款。如果不接受这些条款请不要使用本网站。</div>
        <div class="content">
          <div class="content__item">
            <div class="content__title">网站的使用</div>
            <div class="content__info">上海天存信息技术有限公司（以下简称天存公司）授权您观看和下载本网站（简称“站”）上的内容，但仅供您个人而非商业使用，而且，对于原始内容中所含的版权和其他所有权声明，您必须在其副本中予以保留。您不得以任何方式修改、复制、公开展示、公布或分发这些材料或者以其他方式把它们用于任何公开或商业目的。禁止以任何目的把这些材料用于其他任何网站或网络计算机环境。本站上的内容受版权保护，任何未经授权的使用都可能构成对版权、商标和其他法律的破坏。如果您违反任何条款，您使用本站的授权将自动终止，同时您必须立即销毁任何已下载或打印好的材料。</div>
          </div>
          <div class="content__item">
            <div class="content__title">软件的使用</div>
            <div class="content__info">如果您从本站下载软件，在使用软件时要遵守该软件携带的软件许可协议中的所有许可条款。在您阅读并接受软件许可协议的各项条款之前不得下载或安装这一软件。</div>
          </div>
          <div class="content__item">
            <div class="content__title">用户提交材料</div>
            <div class="content__info">除个人识别信息，其有关内容包含于天存公司在线保密声明，其他任何您发送或邮寄给本站的材料、信息或联系方式均将被视为非保密和非专有（“信息”）。天存公司将对这些信息不承担任何义务。天存公司及其授权人将可因商业或非商业的目的自由复制、透露、分发、合并和以其他方式利用这些信息和所有数据、图像、声音、文本及其他内容。您不得向或从本站邮寄或发送任何非法、威胁、诽谤、中伤、淫秽、色情或其他可能违法的材料。</div>
          </div>
          <div class="content__item">
            <div class="content__title">用户交谈室</div>
            <div class="content__info">天存公司可能（但在法律上不负任何责任）会监控或审查用户在本站上发送或邮寄的信息或相互之间单独交流的任何领域，包括但不限于交谈室、公告牌或其他用户论坛以及任何交流内容。天存公司对有关任何这类交流的内容不承担任何责任，无论它们是否会因版权法引起诽谤、隐私、淫秽或其他方面的问题。天存公司保留删除包含被视为侮辱、诽谤、淫秽或其他不良内容的消息的权利。</div>
          </div>
          <div class="content__item">
            <div class="content__title">链接其他网站</div>
            <div class="content__info">本站到第三方网站的链接仅作为一种方便服务提供给您。如果使用这些链接，您将离开本站。天存公司没有审查过任何第三方网站，对这些网站及其内容不进行控制，也不负任何责任。因此，天存公司对这些网站及其任何信息、软件或其他产品、材料、或因使用它们而可能获得的任何结果不予认可，也不作任何陈述。如果您决定访问任何与本站链接的第三方网站，其风险全部由您自己承担。</div>
          </div>
          <div class="content__item">
            <div class="content__title">免责声明</div>
            <div class="content__info">本站的材料按原样提供，不附加任何形式的保证，包括适销性、适合于特定目的或不侵犯知识产权的保证。此外，天存公司也不保证本站材料的准确性和完整性。天存公司可能在不通知的情况下随时变更本站的内容或在这些内容中介绍的产品和价格。本站中的内容可能已经过期，天存公司不承诺更新它们。本站发布的信息可能是在您本国尚不能得到的产品、程序或服务。您可向当地的天存公司业务联系人咨询以获得有关的信息。可适用的法律可能不允许把隐含担保排除在外，所以上述免责条款可能不适用于您。</div>
          </div>
          <div class="content__item">
            <div class="content__title">责任限度</div>
            <div class="content__info">天存公司及其供应商或本站中提到的第三方对任何损害概不负责（包括但不限于由损失的利润、损失的数据或业务中断而造成的损害），无论这些损害是否由于使用、或不能使用本站的结果、与本站链接的任何网站或者任何这类网站中包含的信息所引起，也不管它们是否有保证、合同、侵权行为或任何其他法律根据以及事前已得到这类损害可能发生的忠告。如果您由于使用本站的信息而导致需要对设备或数据进行维护、修理或纠正，您必须承担由此而造成的所有费用。可适用的法律可能不允许把偶然或间接发生的损害排除在外或对责任范围加以限制，所以上述范围或条款也许不适用于您。</div>
          </div>
          <div class="content__item">
            <div class="content__title">一般原则</div>
            <div class="content__info">天存公司可能随时修改这些条款。您应经常访问本页面以了解当前的条款，因为这些条款与您密切相关。这些条款的某些条文也可能被本站中某些页面上明确指定的法律通告或条款所取代。</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <Footer />
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.intro__header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 126px;
  left: 322px;
  font-size: 40px;
  font-weight: 700;
  font-family: Source Han Sans CN;
  padding-left: 323px;
  color: rgba(112, 112, 112, 1);
  background: rgba(242, 242, 242, 1);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

  @media screen and (max-width: 750px) {
    font-size: 72px;
    padding-left: 90px;
    height: 178px;
  }
}
.intro__main {
  margin: 100px 322px;
  font-size: 20px;
  font-weight: 400;
  font-family: Source Han Sans CN;
  text-align: justify;
  color: rgb(255, 255, 255);
  box-shadow: 3px 6px 9px 0px rgba(0, 0, 0, 0.22);
  border-radius: 12px;
  background: rgb(255, 255, 255);
  overflow: hidden;
  padding: 50px 50px 100px 50px;

  @media screen and (max-width: 750px) {
    margin: 0;

    .title {
      font-size: 54px !important;
      padding-top: 80px;
      padding-bottom: 40px !important;
    }
  }

  .title {
    font-size: 28px;
    font-weight: 600;
    font-family: Source Han Sans CN;
    text-align: center;
    letter-spacing: 1px;
    color: rgba(64, 64, 64, 1);
    padding-bottom: 32px;
  }

  .info {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    font-family: Source Han Sans CN;
    padding-bottom: 26px;
    text-align: center;
    color: rgba(112, 112, 112, 1);
    border-bottom: 2px solid rgba(204, 204, 204, 1);

    @media screen and (max-width: 750px) {
      font-size: 36px;
      text-align: justify !important;
    }
  }

  .content {
    @media screen and (max-width: 750px) {
      .content__title {
        font-size: 48px !important;
      }
      .content__info {
        font-size: 36px !important;
        padding-top: 40px !important;
      }
      .content__sublist {
        padding-left: 55px !important;
      }
      .content__subitem {
        font-size: 30px !important;
        padding-left: 35px !important;
        &::before {
          width: 25px !important;
          height: 25px !important;
          top: 35px !important;
        }
      }
    }

    .content__item {
      margin-top: 30px;
    }

    .content__title {
      font-size: 24px;
      font-weight: 600;
      font-family: Source Han Sans CN;
      text-align: justify;
      letter-spacing: 1px;
      line-height: 1.8;
      color: rgba(253, 147, 30, 1);
    }
    .content__info {
      font-size: 16px;
      font-weight: 400;
      font-family: Source Han Sans CN;
      line-height: 1.8;
      letter-spacing: 1px;
      text-align: justify;
      padding-top: 10px;
      color: rgba(93, 93, 93, 1);
    }
    .content__sublist {
      padding-left: 45px;
    }
    .content__subitem {
      position: relative;
      font-size: 16px;
      font-weight: 400;
      font-family: Source Han Sans CN;
      text-align: justify;
      line-height: 1.5;
      padding-left: 26px;
      color: rgba(93, 93, 93, 1);
      padding-top: 10px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 16px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: rgba(253, 147, 30, 1);
      }
    }
  }

  p + p {
    padding-top: 40px;
  }
}
</style>
